/*
AboutDes.css is the style sheet used to stylize the AboutDes.js component file and format all of its classes and objects within it
*/


 .vid {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
}
 .overlay {
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
 }

 .about-container {
  height: auto;
  width: 100%;
  display: flex;
  color: #d2d2d2;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 2700px;
  align-items: center;
  object-fit: contain;
  position: relative;
 }

 .description {
  height: 100vh;
  width: 70%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
 }

.subtitle {
  font-size: 20px;
  display: flex;
  width: 50%;
}
h1 {
  font-size: 90px;
}

h4 {
  display: flex;
  padding-top: 50px;
  font-size: 40px;
 }
.facts {
  font-size: 30px;
  margin: 40px;
  display: flex;
}
.diagram {
  display: flex;
  margin: 20px;
}
 .title {
  margin-top: 200px;
  width: 70%;
  text-align: center;
 }

 hr.solid {
  border-top: 3px solid #d2d2d2;
 }
 .creator {
  padding-block: 40px;
  color: #d2d2d2;
  width: 100%;
 }


 @media screen and (max-width: 960px) {

  .about-container {
    height: auto;
    color: black;
    background-color: #d2d2d2;
    width: 100%;
    margin-bottom: 10;

  }
  .description {
    width: 80%;
    margin: auto;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
   }
   .title {
    width: 80%;
   }

   h1 {
    font-size: 50px;

   }

  .para {
  font-size: 20px;
 }
  .facts {
  font-size: 20px;
  width: 90%;
 }

 .diagram {
  width: 100%;
  height: auto;
 }
 .notes {
  font-size: 15px;
 }

 .subtitle {
  font-size: 15px;
  width: 80%;
}

 }
