.footer-container {
  background-color: #262323;
  color: #d2d2d2;
  align-items: center;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  width: 100%;
  padding: 50px;
}


p {
  font-size: 15px;
}

.extra {
  padding: 40px;
}
