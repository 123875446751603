* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;

}
.sources {
  display: flex;
  margin-top: 200px;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
