.info-wrapper {
  background-color: #d2d2d2;
  font-size: 20px;
}

.scaling-desc {
  font-size: 30px;
}

h5 {
  font-size: 30px;
  padding-left: 10%;
}

.subTitle {
  font-size: 23px;
  font-weight: bold;
}

.ex {
}
