

.about-content {
  display: flex;
  justify-content:center;
  flex-direction: column;
}



.creator {
  background-color: rgba(0, 0, 0,0.3);
  display: flex;
  color: #d2d2d2;
  flex-direction: column;
  margin-bottom: auto;
  padding: 2%;
}


@media screen and (max-width: 960px){
.about-content {
  display: flex;
  justify-content:center;
  flex-direction: column;
  background-color: #d2d2d2;
}

}
