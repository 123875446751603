.video2 {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
}

.wrapperSource {
  padding: 3%;
  display: flex;
  /* color: #262323; */
  color: #d2d2d2;
  align-items: center;
  flex-direction: column;
}

.link-cont {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #d2d2d2;
    /* color:#262323; */
    padding: 2%;
    flex-direction: column;
  }
  hr.solid {
    border-top: 3px solid #262323;
    padding: 10px;
   }

@media screen and (max-width: 960px){
  .wrapperSource {
    padding: 8%;
    display: flex;
    color: #d2d2d2;
    background-color: black;
    align-items: center;
    flex-direction: column;
  }

  .link-cont {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
  }

  h3 {
    font-size: 25px;
  }

  .links {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    padding: 1%;
    color:bisque;
  }


}
