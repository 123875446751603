.App {
  background-color: #d2d2d2;
  padding: 30px;
  height: 100%;
  width:100%;
  justify-content: center;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: column;
}
.leftContainer {
  display: flex;
  width: fit-content;
  height: 100%;
  flex-direction: column;
  color: #d2d2d2;
}

h3 {
  padding-top: 3%;
  padding-bottom: 50px;
  display: flex;
}


img {
  height: auto;
  width: 100%;
}
.currentImage {
  height: 80%;
  justify-content: center;
  padding-inline: 5%;
  align-items: center ;
}

.leftContainer {
  padding-left: 5%;
  padding-bottom: 1%;
}

.left {
  color: #d2d2d2;
}
.date {
  font-size: 15px;
  padding-bottom: 5px;
}

.title-target {
  padding: 1%;
  font-size: 20px;
}


.mobile-container {
  display: none;
}


@media screen and (max-width: 960px) {
  /* .App { */
    /* padding: 30px; */
    /* justify-content: center; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
  /* } */

  .App {
    display: none;
  }
  .mobile-container {
    display: flex;
    justify-content: left;
    flex-direction: column;
    background-color: #262323;
    color: #d2d2d2;
    padding: 2%;

  }
  .title-target {
    background-color: #333131;
  }
  .date {
    padding-top: 10px;
  }

  .slide-description {
    padding: 2%;
  }

  .right {
    display: none;
  }

  .left {
    display: none;
  }
  .desc2 {
    display: none;
  }
  .carousel .thumbs {
    display: none;
  }

  .carousel .control-dots {
    display: none;
  }

  .carousel.carousel-slider .control-arrow{
    display: none;
}

}
